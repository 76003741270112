import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useStockList() {
    const toast = useToast()
    const refListTable = ref();
    let blankData = {
        start_date:'',
        end_date:'',
        keyword:''
    }
    const filterData = ref(JSON.parse(JSON.stringify(blankData)))
    const tableColumns = [
        // { field: 'no', label: '#', sortable: false},
         { field: 'created_at', label: 'วันที่', sortable: false},
         { field: 'no', label: 'เลขที่บริการ/ใบเสร็จ', sortable: false},
         { field: 'code_number', label: 'Lot', sortable: false},
         { field: 'product_name', label: 'ยา/อุปกรณ์', sortable: false},
         { field: 'value', label: 'จำนวน', sortable: false},
         { field: 'balance', label: 'คงเหลือ', sortable: false },
       
 
 
 
     ]
    const pageLength = ref(10);
    const currentPage = ref(1);
    const Total = ref(0);
    const items = ref([]);
    const itemsPrint = ref([]);

    watch([currentPage, pageLength], () => {
        items.value = []
        fetchData();
    })
    const ApplyFilter = async ()=>{
        items.value = []
        fetchData();

    }
    const clearFilter = async ()=>{
        let blankDatac = {
            start_date:'',
            end_date:'',
            keyword:''
        }
        items.value = []
        filterData.value = blankDatac;
        fetchData();

    }
    const fetchData = async ()=>{
      const userStorage = JSON.parse(localStorage.getItem('userData'));
        const offset = pageLength.value*(currentPage.value-1);
        let response =  await store.dispatch('app-report/fetchStocksData', {
                // id:queue_id,
                  limit: pageLength.value,
                  offset:offset,
                  keyword:filterData.value.keyword,
                  start_date:filterData.value.start_date,
                  end_date:filterData.value.end_date,
                  branch_id: userStorage.branch_id
                
                  
              
                });
                console.log('stocks',response);
          if(response.data.success){
            items.value = response.data.data;
           
  
          }
         
        
  
      }
      const getPrintData = async()=>{
        const userStorage = JSON.parse(localStorage.getItem('userData'));
        itemsPrint.value = []
        let response =  await store.dispatch('app-report/fetchStocksData', {
            // id:queue_id,
            
              keyword:filterData.value.keyword,
              start_date:filterData.value.start_date,
              end_date:filterData.value.end_date,
              branch_id: userStorage.branch_id
            
              
          
            });
          
      if(response.data.success){
        itemsPrint.value = response.data.data;
       

      }

      }
      fetchData();
    return {
        filterData,
        refListTable,
        tableColumns,
        items,
        pageLength,
        currentPage,
        Total,
        ApplyFilter,
        clearFilter,
        itemsPrint,
        getPrintData
    }
}